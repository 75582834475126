import { ref } from 'vue'

const startTime = ref(0)
const pagesVisited = ref(1)

function setStartTime (time: number): void {
	startTime.value = time
}

function incrementPagesVisited (): void {
	pagesVisited.value++
}

export function useMetrics () {
	return {
		startTime,
		pagesVisited,
		setStartTime,
		incrementPagesVisited
	}
}
