<template>
	<Disclosure
		v-slot="{ close }"
		as="nav"
		class="fixed top-0 z-30 w-full bg-mx-navy md:hero-image hero-lengths"
	>
		<LazyAlertBanner
			v-if="showAlertBanner"
			:alert="alert"
		/>
		<section class="container px-4 mx-auto md:py-4">
			<div class="flex justify-end h-16 px-4 whitespace-nowrap md:justify-between">
				<div class="flex w-full">
					<NavLogo
						:show-alert-banner="showAlertBanner"
						@close="close()"
					/>
					<NavDesktopMainLinks class="w-full" />
				</div>
				<NavDesktopAccountLinks
					:is-logged-in="loggedIn"
					:account-url="accountUrl"
				/>
				<LazyNavMobileHamburgerMenu />
			</div>
		</section>
		<NavDrawer
			:is-logged-in="loggedIn"
			:account-url="accountUrl"
			@nav-link-clicked="handleNavigationClick(close)"
		/>
	</Disclosure>
</template>

<script setup lang="ts">

import { Disclosure } from '@headlessui/vue'
import { toRefs } from 'vue'
import type { AlertProps } from '@/types/alert'
import { useAuth } from '@/composables/useAuth'

const { loggedIn, accountUrl } = useAuth()

const props = defineProps({
	showAlertBanner: {
		type: Boolean,
		default: false
	},
	alert: {
		type: Object as PropType<AlertProps>,
		required: true
	}
})

const { showAlertBanner } = toRefs(props)

const handleNavigationClick = (close: Function) => {
	close()
}
</script>
