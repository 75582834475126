import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import type { HeroContentProps } from '@/components/home/types'

export function getHeroContent () {
	const route = useRoute()
	const { t, te } = useI18n({
		useScope: 'global'
	})

	const content = computed<HeroContentProps>(() => {
		const routeName = typeof route?.name === 'string' ? route.name?.split('___')?.[0] : ''
		const routeQuery = route.query[Object.keys(route.query)[0]]

		if (route.meta?.useDefaultHeroContent) {
			return {
				title: t('default-hero.title'),
				subtitle: t('default-hero.subtitle'),
				description: t('default-hero.description')
			}
		}
		if (typeof route?.name === 'string') {
			return {
				title: te(`${routeName}.${routeQuery}`) ? t(`${routeName}.${routeQuery}`) : t(`${routeName}.title`)
			}
		} else {
			return {}
		}
	})

	return {
		content
	}
}
