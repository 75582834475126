<template>
	<div class="hidden md:max-w-lg lg:mx-8 md:flex md:justify-between md:space-x-2">
		<div
			v-for="item in navLinks"
			:key="$rt(item.name)"
			class="inline-flex items-center h-full"
			@mouseleave="hideDropdown"
		>
			<div
				v-if="isAboutLink($rt(item.href))"
				class="relative text-sm text-white rounded-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-mx-navy focus-visible:ring-mx-orange focus-visible:ring-opacity-80 lg:text-lg font-base hover:text-mx-orange"
			>
				<NuxtLink
					:to="$rt(item.href)"
					@mouseenter="showDropdown"
				>
					<div :class="{ 'border-b border-mx-orange' : underlineNavLink($rt(item.href)) }">
						{{ $rt(item.name) }}
					</div>
				</NuxtLink>
				<Transition
					enter-active-class="transition-all duration-200 ease-out"
					leave-active-class="transition-all duration-200 ease-in"
					enter-from-class="opacity-0"
					leave-to-class="opacity-0"
				>
					<div
						v-show="isDropdownVisible"
						class="absolute"
					>
						<div
							:class="[
								isDropdownVisible ? 'h-32' : 'h-0',
								'shadow-lg transition-all duration-500 fixed flex flex-col justify-around -ml-4 py-2 text-sm font-semibold bg-white rounded-b-lg w-[150px]'
							]"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="options-menu"
						>
							<NuxtLink
								v-for="link in aboutLinks"
								:key="$rt(link.name)"
								class="px-4 py-1 text-black transition-all hover:underline hover:decoration-2 hover:underline-offset-2 hover:decoration-mx-orange"
								:to="$rt(link.href)"
							>
								{{ $rt(link.name) }}
							</NuxtLink>
						</div>
					</div>
				</Transition>
			</div>
			<NuxtLink
				v-else
				class="text-sm text-white rounded-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-mx-navy focus-visible:ring-mx-orange focus-visible:ring-opacity-80 lg:text-lg font-base hover:text-mx-orange"
				:to="$rt(item.href)"
				@mouseenter="hideDropdown"
			>
				<section>
					<div :class="{ 'border-b border-mx-orange' : underlineNavLink($rt(item.href)) }">
						{{ $rt(item.name) }}
					</div>
				</section>
			</NuxtLink>
		</div>
		<Localization />
	</div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import type { NavLink } from '@/components/nav/types'

const { tm } = useI18n({
	useScope: 'global'
})

const route = useRoute()

const navLinks = computed<NavLink[]>(() => tm('header-nav'))

const aboutLinks = computed<NavLink[]>(() => tm('about-dropdown-links'))

const underlineNavLink = (href: string) => {
	return href === route.path
}

const isAboutLink = (href: string) => {
	return !!href?.includes('about')
}

const isDropdownVisible = ref(false)

const hideDropdown = () => {
	isDropdownVisible.value = false
}

const showDropdown = () => {
	isDropdownVisible.value = true
}
</script>
