<template>
	<NuxtLink
		:to="switchLocalePath(alternativeLanguage.abbrev)"
		class="inline-flex items-center text-sm text-white rounded-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-mx-navy focus-visible:ring-mx-orange focus-visible:ring-opacity-80 lg:text-lg font-base hover:text-mx-orange"
		@click="setLanguage(alternativeLanguage.abbrev)"
	>
		{{ alternativeLanguage.name }}
	</NuxtLink>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useLanguageLocalStorage } from '@/composables/useLocalStorage'

const switchLocalePath = useSwitchLocalePath()
const { setLanguage } = useLanguageLocalStorage()

const { locale } = useI18n()
const alternativeLanguage = computed(() => {
	return locale.value === 'en'
		? {
			abbrev: 'es',
			name: 'Español'
		}
		: {
			abbrev: 'en',
			name: 'English'
		}
})

</script>
