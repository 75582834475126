<template>
	<NuxtLink
		class="px-2 py-2.5 text-white uppercase rounded-lg bg-mx-orange hover:bg-mx-orange-muted"
		:class="[ inSpanish ? 'px-2': 'px-5' ]"
		:to="localePath('/get-fiber')"
	>
		{{ $t('buttons.get-fiber') }}
	</NuxtLink>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { locale } = useI18n()
const localePath = useLocalePath()

const inSpanish = computed(() => {
	return locale.value === 'es'
})
</script>
