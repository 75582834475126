<template>
	<NavMobileSlideOver>
		<template #header>
			<div class="absolute top-0 right-0 w-full h-24 bg-mx-navy" />
			<div class="absolute top-6 right-6">
				<div class="flex items-center">
					<Localization class="inline-flex items-center px-4 text-sm text-gray-500 font-base hover:text-mx-orange" />
					<GetFiber
						v-if="!isLoggedIn"
						@click="handleNavigationClick('get-fiber')"
					/>
				</div>
			</div>
		</template>
		<template #content>
			<div class="container grid grid-cols-1 pt-20 overflow-y-scroll text-right divide-y divide-gray-400">
				<div
					v-for="item in headerNav"
					:key="$rt(item.name)"
					class="flex flex-col items-center w-full h-24 pr-4 text-xl text-white transition-all duration-500"
					:class="[
						{ 'flex flex-col': item.href === '/about'},
						showAboutLinks($rt(item.href)) ? 'h-[22rem]' : 'h-24'
					]"
				>
					<div class="flex items-center justify-end w-full h-24">
						<NuxtLink
							:to="$rt(item.href)"
							class="flex h-24 py-8 pr-4 text-xl text-white"
							@click="handleNavigationClick(item.name)"
						>
							{{ $rt(item.name) }}
						</NuxtLink>
						<div v-if="isAboutLink($rt(item?.href))">
							<button
								@click="toggleAbout"
							>
								<ChevronDownIcon
									:class="[
										{ 'transform -rotate-180': aboutOpen },
										'h-6 w-6 transition-all ease-in text-white text-center',
									]"
									aria-hidden="true"
								/>
							</button>
						</div>
					</div>
					<div
						class="flex flex-col w-full mr-24 text-right transition-all duration-500"
						:class="[
							showAboutLinks($rt(item.href)) ? 'visible h-full opacity-100' : 'invisible h-0 opacity-0'
						]"
					>
						<NuxtLink
							v-for="aboutItem in aboutLinks"
							:key="$rt(aboutItem.name)"
							:to="$rt(aboutItem.href)"
							class="pb-9"
							@click="handleNavigationClick(item.name)"
						>
							{{ $rt(aboutItem.name) }}
						</NuxtLink>
					</div>
				</div>
				<NuxtLink
					v-if="!isLoggedIn"
					:to="localePath('/login')"
					class="flex items-center content-center justify-end h-24 pr-4 text-xl text-white"
					@click="handleNavigationClick('sign-in')"
				>
					{{ $t('buttons.login') }}
				</NuxtLink>
				<a
					v-else
					:href="accountUrl"
					class="flex items-center content-center justify-end h-24 pr-4 text-xl text-white"
				>
					{{ $t('your-account') }}
				</a>
			</div>
		</template>
	</NavMobileSlideOver>
</template>

<script setup lang="ts">
import { ACCOUNT_URL } from '@/constants/index.js'
import { useI18n } from 'vue-i18n'
import { toRefs, computed } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { MOBILE_NAV_CLICK } from '@/constants/gtm/events.js'
import type { MobileNavEvent } from '@/types/gtm/events'
import { useTrackEvent } from '@/composables/useEventTracking'
import type { SlideOverItems } from '@/components/nav/types'

const { tm } = useI18n({
	useScope: 'global'
})
const localePath = useLocalePath()

const headerNav = computed(() => tm('header-nav') as SlideOverItems[])
const aboutLinks = computed(() => tm('about-dropdown-links') as SlideOverItems[])

const props = defineProps({
	isLoggedIn: {
		type: Boolean,
		default: false
	},
	accountUrl: {
		type: String,
		default: ACCOUNT_URL
	}
})

const { isLoggedIn } = toRefs(props)

const emit = defineEmits([ 'nav-link-clicked' ])

const handleNavigationClick = (name: string) => {
	const trackedEvent: MobileNavEvent = {
		event: MOBILE_NAV_CLICK,
		category: 'Header',
		action: 'click',
		label: 'Header Nav - Mobile Click',
		href: name
	}
	useTrackEvent(trackedEvent)
	emit('nav-link-clicked')
	aboutOpen.value = false
}

const aboutOpen = ref(false)

const showAboutLinks = (href: string) => {
	return isAboutLink(href) && aboutOpen.value
}

const toggleAbout = () => {
	aboutOpen.value = !aboutOpen.value
}

const isAboutLink = (href: string) => {
	return !!href?.includes('about')
}

</script>
