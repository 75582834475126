<template>
	<div class="hidden md:ml-6 md:flex md:items-center">
		<div v-if="!isLoggedIn">
			<NuxtLink
				:to="localePath('/login')"
				class="inline-flex items-center text-sm text-white rounded-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-mx-navy focus-visible:ring-mx-orange focus-visible:ring-opacity-80 lg:text-lg hover:text-mx-orange"
				:class="[ inSpanish ? 'px-2' : 'px-4' ]"
			>
				{{ $t('buttons.login') }}
			</NuxtLink>
			<GetFiber class="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-mx-navy focus-visible:ring-mx-orange focus-visible:ring-opacity-80" />
		</div>
		<div v-else>
			<a
				:href="accountUrl"
				class="inline-flex items-center px-1 text-sm text-white lg:text-lg hover:text-mx-orange"
			>
				{{ $t('your-account') }}
			</a>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ACCOUNT_URL } from '@/constants/index.js'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { locale } = useI18n()
const localePath = useLocalePath()

const props = defineProps({
	isLoggedIn: {
		type: Boolean,
		default: false
	},
	accountUrl: {
		type: String,
		default: ACCOUNT_URL
	}
})

const { isLoggedIn } = toRefs(props)

const inSpanish = computed(() => {
	return locale.value === 'es'
})
</script>
