<template>
	<div
		:class="[
			'flex flex-col h-screen',
			padding
		]"
	>
		<div class="flex flex-col grow">
			<TheHeader
				:show-alert-banner="showAlertBanner"
				:alert="alert"
			/>
			<slot name="hero">
				<HomeHero
					:show-search="showAddressSearch"
					:content="content"
					:show-alert-banner="showAlertBanner"
					:h1-elsewhere="h1Elsewhere"
				/>
			</slot>
			<slot name="default" />
		</div>
		<TheFooter
			class="sticky w-full top-full"
		/>
	</div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, ref, onMounted } from 'vue'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { useShowAlert, useAlert } from '@/composables/useState'
import { isAlertResponseValid } from '@/types/api'
import { getHeroContent } from '@/composables/getHeroContent'
import MyWorker from '@/assets/workers/alertWorker?worker'
import { useAuth } from '@/composables/useAuth'

const { loggedIn } = useAuth()

const { content } = getHeroContent()

const route = useRoute()

const showAlert = useShowAlert()
const alert = useAlert()
const worker = ref<Worker | null>(null)

const { reportError } = useErrorReporter(useBugsnag().notify)

const showAddressSearch = computed(() => {
	return !!route.meta?.showAddressSearch
})

const showAlertBanner = computed(() => {
	return showAlert.value && (route?.path?.includes('contact') || loggedIn.value)
})

const padding = computed(() => {
	if (showAlertBanner.value) {
		// Desktop: 176px = 96px (header) + 80px (banner)
		// Mobile: 144px = 64px (header) + 80px (banner)
		return 'pt-[144px] md:pt-[176px]'
	} else {
		return 'pt-16 md:pt-24'
	}
})

const h1Elsewhere = computed(() => {
	return !!route?.meta?.h1Elsewhere
})

const checkForAlerts = () => {
	worker.value = new MyWorker()
	worker.value.onmessage = (res) => {
		if (!res.data?.length) {
			alert.value.title = ''
			alert.value.message = ''
			alert.value.active = false
			showAlert.value = false
			return
		}
		if (res?.data?.isError) {
			reportError(res?.data?.error)
			return
		}
		if (isAlertResponseValid(res?.data?.[0])) {
			reportError('Invalid data returned from alerts endpoint')
			return
		}
		const { title, message, active } = res.data[0]
		alert.value.title = title || ''
		alert.value.message = message || ''
		showAlert.value = active ?? false
	}
}

onMounted(() => {
	checkForAlerts()
	document.addEventListener('visibilitychange', () => {
		if (document.visibilityState === 'visible' && !worker.value) {
			checkForAlerts()
		} else {
			worker.value?.terminate()
			worker.value = null
		}
	})
})
</script>
